import React from 'react';
import injectSheet from 'react-jss';
import styles from './ProductValueSectionStyles';

const ProductValueSection = ({ hasGrayBackground, classes, values, imageOnLeft, sectionTitle }) => {
  const valuesPrintout = values.map((value) => {
    const { description, title, list, image } = value;
    const listPrintout = list.map((item) => <li key={item}>{item}</li>);
    const content = (
      <div className={classes.containerContent}>
        <h3>{title}</h3>
        <p>{description}</p>
        <ul className={classes.list}>{listPrintout}</ul>
      </div>
    );
    const imagePrintout = (
      <div className={classes.containerImage}>
        <img alt={title} src={image} />
      </div>
    );
    return (
      <div
        className={`${classes.containerValue} ${!imageOnLeft && classes.containerValueWrapReverse}`}
        key={title}
      >
        <>
          {imagePrintout} {content}
        </>
      </div>
    );
  });
  return (
    <div className={`${classes.containerOuter} ${hasGrayBackground && classes.grayBackground}`}>
      <div className={classes.containerInner}>
        <h1 className={classes.sectionTitle}>{sectionTitle}</h1>
        {valuesPrintout}
      </div>
    </div>
  );
};

export default injectSheet(styles)(ProductValueSection);
