import andrewConstruction from './andrewLinkedInBW.png';

const posts = [
  {
    url: '/news/emerging-construction-technology-transform-urban-land',
    title: 'How emerging construction technology can transform the value of urban land.',
    text:
      "Emerging construction technology, such as the Preconstruction Intelligence tools we're developing at Join, can radically change the value of land, enabling denser development that benefits both owners and cities. In this post, we’ll walk through the process that developers use to value land parcels, then show how technology-driven reductions in hard construction costs can impact that analysis. The result? A win for owners and municipalities: increased value, taller buildings, and more sustainable development.",
    photo: andrewConstruction
  }
];

export default posts;
