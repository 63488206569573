import theme from '../../../theme';

const styles = {
  ...theme.presets,
  hero: {
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      marginBottom: '75px', // make room for image placement
    },
  },
  tangramLeft: {
    ...theme.presets.tangram,
    left: -200,
    bottom: 0,
  },
  tangramRight: {
    ...theme.presets.tangram,
    right: -150,
    top: 80,
  },
  headlineSection: {
    maxWidth: 582,
    margin: '0 10%',
    marginBottom: 30,
  },
  valuePropSection: {
    ...theme.presets.innerContent,
    [`@media all and (max-width: ${theme.breakpoints.small}px)`]: {
      padding: '100px 30px',
    },
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  valueProp: {
    maxWidth: 280,
    marginTop: 30,
    [`@media all and (max-width: ${theme.breakpoints.small}px)`]: {
      maxWidth: '100%',
    },
  },
  valuePropPhoto: {
    margin: 0,
    [`@media all and (max-width: ${theme.breakpoints.small}px)`]: {
      margin: '0 -30px',
      width: 'calc(100% + 60px)',
    },
  },
  valuePropIcon: {
    height: 124,
  },
};

export default styles;
