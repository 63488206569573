import generalContractors from './media/generalContractors.svg';
import owners from './media/owners.svg';
import contractors from './media/contractors.svg';
import design from './media/design.svg';

/*
WHO WE SERVE PERSON OBJECT
{
    anchor: '' // used for anchor links
    description: '',
    icon: <svg/>,
    list: [''],
    title: '',
}
*/

// The order of this array determines the order printed out on the page
export const whoWeServeArray = [
  {
    anchor: 'general-contractors',
    description:
      'Join helps discover, track and analyze how new ideas and options being explored during preconstruction impact the cost estimates in real time. Join creates presentation materials, detailed logs, and historical reports that make you look amazing in front of owners and win more business.',
    icon: generalContractors,
    list: [
      'Estimating teams use Join to collaborate while getting to GMP.',
      'Preconstruction managers and executives use Join communicate real-time cost status to owners and the Design team.',
      'Business Development and Marketing teams use Join to differentiate themselves and win more business.',
      'Operations teams use Join to understand what decisions have been made in preconstruction and why.',
    ],
    title: 'General contractors',
  },
  {
    anchor: 'owners-and-developers',
    description:
      'De-risk your project using dashboards in Join to clearly show progress towards your project objectives. Detailed logs with specific information about each decision, and the reasons why they were made, provide owners and developers a complete source of truth.',
    icon: owners,
    list: [
      'Owners use Join to determine the financial health of their projects as they evolve through design and preconstruction.',
      'Project executives have consistent reporting available across their portfolio of projects.',
      'Construction Managers use Join to improve communication and collaboration between the project stakeholders.',
    ],
    title: 'Owners & developers',
  },
  {
    anchor: 'design-team',
    description:
      'Design team collaboration is essential to ensure design intent is clearly understood while working closely with owners and general contractors during preconstruction. Join allows the design team to visually see real-time cost impacts associated with the design options being explored.',
    icon: design,
    list: [
      'Project Managers use Join to understand project cost status and coordinate effectively with the design team.',
      'Project Leads use Join to share design ideas that they are exploring with the owner and general contractor.',
      'Designers use Join to augment ideas under consideration and get real-time cost feedback.',
    ],
    title: 'Design team',
  },
  {
    anchor: 'trade-contractors',
    description:
      'Trade contractors use Join to provide critical information needed for a project trying to get to GMP without the hassles of email and confusing spreadsheets. This leads to greater cost confidence all around and the benefits of winning more work through greater collaboration.',
    icon: contractors,
    list: [
      'Estimators are able to provide up-to-date information to GCs as they evaluate materials and scopes of work.',
      'Project Managers are able to respond to questions in real-time in Join without having separate email threads, phone calls, and side conversations.',
      'Business Development uses Join to get involved in projects earlier and win proposals with confidence.',
    ],
    title: 'Trade contractors',
  },
];
