import React from 'react';
import injectSheet from 'react-jss';
import TestimonialsStyles from './TestimonialsStyles';

const Testimonials = ({ classes, testimonialsList }) => {
  const testimonials = testimonialsList.map((testimonial) => {
    const { bio, copy, photo } = testimonial;
    return (
      <div className={classes.containerTestimonial} key={testimonial.bio}>
        <div className={classes.testimonialBody}>
          <img alt={`${bio}`} src={photo} />
          {copy}
        </div>
        <div className={classes.testimonialBio}>{bio}</div>
      </div>
    );
  });
  return (
    <div className={classes.containerOuter}>
      <div className={classes.containerInner}>{testimonials}</div>
    </div>
  );
};

export default injectSheet(TestimonialsStyles)(Testimonials);
