import React from 'react';
import injectSheet from 'react-jss';

import theme from '../../../theme';

const styles = {
  ...theme.presets,
  headlineSection: {
    maxWidth: 582,
    margin: '0 10%',
  },
};

const Privacy = ({ classes }) => (
  <div>
    <div className={classes.content}>
      <h1>Privacy Policy</h1>
      <div>Effective date: 1 September 2020</div>
      <p>
        Join, Inc. (collectively “Join”, "us", "we", or "our") is committed to respecting and
        protecting your privacy. This Privacy Policy describes our practices regarding the
        collection, use, and disclosure of information, including personal information, when you use
        our websites, web-based services and applications, products, mobile apps and services, and
        events (“Offerings”) and the choices you have associated with that data.
      </p>
      <p>
        Join collects several different types of information, including personal information, for
        various purposes to provide and improve the quality of the Offerings. By using these
        Offerings, you agree to the collection and use of personal information in accordance with
        this policy. If Join is acting as service provider for you, Join will enter into a separate
        agreement with you which shall control such relationship.
      </p>
      <p>
        Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the
        same meanings as defined in the Join General Terms of Use.
      </p>
      <h2>Information we collect</h2>
      <p>
        Join collects your personal information in the course of providing the Offerings to you.
      </p>
      <h2>Information you give Join</h2>
      <p>Join collects information you give us when you:</p>
      <ul>
        <li>Search for, subscribe to, or purchase the Offerings</li>
        <li>Create or administer your Join account</li>
        <li>
          Configure your settings for, provide data access permissions for, or otherwise interact
          with the Offerings
        </li>
        <li>Register for or attend a Join event</li>
        <li>Communicate with Join by phone, email, chat or otherwise</li>
        <li>Complete a questionnaire, a support ticket, or other information request form</li>
        <li>Post on any the Offerings</li>
        <li>Employ Join notification services</li>
      </ul>
      <p>In the use of the Offerings, you might supply us with such information as:</p>
      <ul>
        <li>
          Your name, email address, physical address, phone number, and other similar contact
          information
        </li>
        <li>Payment information, including credit card and bank account information</li>
        <li>Information about your location</li>
        <li>Information about your company or organization</li>
        <li>
          Information about your projects, including location, scope and budget, decisions and their
          related financial, schedule and resource impact
        </li>
        <li>
          Information about your contacts or colleagues with which you interact in the normal course
          of using the the Offerings
        </li>
        <li>
          Usernames, aliases, roles, and other authentication and security credential information
        </li>
        <li>
          Feedback, testimonials, inquiries, support requests, phone conversations, chat sessions,
          and emails with or to us
        </li>
        <li>
          Your image, voice, and other identifiers that are personal to you when you attend a Join
          event or use certain the Offerings
        </li>
        <li>
          Information regarding identity, including government-issued identification information
        </li>
        <li>
          Corporate and financial information, including VAT numbers and other tax identifiers
        </li>
      </ul>
      <h2>Information automatically collected by Join</h2>
      <p>
        Join automatically collects certain types of information when you interact with the the
        Offerings, such as when you:
      </p>
      <ul>
        <li>Visit, interact with, or use the the Offerings</li>
        <li>Download content from Join</li>
        <li>Open email messages or click on links in email messages from us</li>
        <li>Interact or communicate with us at Join events, customer support activities, etc.</li>
      </ul>
      <p>Examples of the information we automatically collect include:</p>
      <ul>
        <li>Authentication and security credential information</li>
        <li>Content interaction information including duration, downloads, etc.</li>
        <li>
          Metrics, such as usage, technical errors, diagnostic reports, your settings preferences,
          backup information, API calls, and other logs when using the the Offerings
        </li>
        <li>
          Network and connection information, such as your internet protocol (IP) address used for
          your connection to the internet and information about your internet service provider
        </li>
        <li>
          Computer and device information, such as device, application, or browser type and version,
          browser plug-in type and version, operating system, time zone setting, etc.
        </li>
        <li>Location information of your device or computer</li>
        <li>
          Content you viewed or searched for, page response times, download errors, and page
          interaction information (such as scrolling, clicks, and mouse-overs)
        </li>
        <li>Email addresses and phone numbers used to contact us</li>
        <li>Identifiers and information contained in cookies (see the Join policy on cookies).</li>
      </ul>
      <h2>Information from other sources</h2>
      <p>
        Join may collect information from other sources, including third party companies and
        individuals that facilitate or provide the Offerings on our behalf ("Service Providers") or
        to perform services or assist in analyzing how the Offerings are used, including:
      </p>
      <ul>
        <li>
          Marketing, sales generation, and recruitment information, including your name, email
          address, physical address, phone number, and other similar contact information
        </li>
        <li>
          Subscription, purchase, support, or other information about your interactions with the
          Offerings by Service Providers (such as training events)
        </li>
        <li>Search results and links, including paid listings</li>
      </ul>
      <h2>Join policy on cookies</h2>
      <p>
        Join uses cookies and similar tracking technologies such as beacons, tags, and scripts to
        collect and track information and to analyze and improve the Offerings. You can instruct
        your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
        you do not accept cookies, you may not be able to use aspects of the Offerings. Example use
        of cookies include session cookies to help operate the Offerings, preference cookies to
        remember your personal preferences and settings, and security cookies for security purposes.
      </p>
      <h2>How we use information</h2>
      <p>
        Join uses the collected data primarily to ensure you can securely access, utilize and
        benefit from the Offerings. Join uses your data to:
      </p>
      <ul>
        <li>Provide, maintain and improve the Offerings</li>
        <li>Notify you about changes to the Offerings</li>
        <li>
          With your consent, notify you of new Join products and services, webinars, special offers,
          marketing opportunities, etc.
        </li>
        <li>
          Allow you to participate in interactive features of the Offerings when you choose to do so
        </li>
        <li>Provide customer care and support</li>
        <li>Monitor the usage of the Offerings</li>
        <li>Detect, diagnose, and address technical issues</li>
        <li>
          Aggregate insights and statistics that do not identify you or your projects to enhance the
          Offerings
        </li>
        <li>
          Comply with law, using your data as we believe necessary or appropriate to comply with
          applicable laws, lawful request, and legal process, such as to respond to subpoenas or
          request from government authorities.
        </li>
        <li>
          Disclose to law enforcement, government authorities, and private parties as we believe
          necessary or appropriate to: (a) protect our, your or others’ rights, privacy, safety or
          property (including by making and defending legal claims); (b) enforce the terms and
          conditions that govern the Offerings; and (c) protect, investigate and deter against
          fraudulent, harmful, unauthorized, unethical or illegal activity.
        </li>
      </ul>
      <h2>Information we share</h2>
      <p>
        Join does not sell personal information to others and shares personal information only as
        described below.
      </p>
      <h2>Join Service Providers</h2>
      <p>
        Join may employ other companies and individuals to perform functions on our behalf,
        including sending communications, processing payments, assessing credit and compliance
        risks, analyzing data, providing marketing and sales assistance (including advertising and
        event management), conducting customer relationship management, providing training, etc.
        These third party Service Providers may have access to personal information needed to
        perform their functions, but may not use it for other purposes. Further, they must process
        that information only as permitted by applicable data protection law.
      </p>
      <h2>Transfer of data</h2>
      <p>
        Your information, including personal information, may be transferred to and maintained
        outside of your state, province, country or other governmental jurisdiction where the data
        protection laws may differ than those from your jurisdiction.
      </p>
      <p>
        If you are located outside United States and choose to provide information to us, please
        note that we transfer the data, including personal information, to the United States and
        process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such information
        represents your agreement to that transfer.
      </p>
      <p>
        Join will take all steps reasonably necessary to ensure that your data is treated securely
        and in accordance with this Privacy Policy and no transfer of your personal information will
        take place to an organization or a country unless there are adequate controls in place
        including the security of your data and other personal information.
      </p>
      <h2>Disclosure of data</h2>
      <p>
        Join may disclose your personal information in the good faith belief that such action is
        necessary to meet legal requirements, including to:
      </p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of Join</li>
        <li>Prevent or investigate possible wrongdoing in connection with the Offerings</li>
        <li>Protect the personal safety of users of the Offerings or the public</li>
        <li>Protect against legal liability</li>
      </ul>
      <p>
        Join may also sell, transfer or otherwise share some or all of its business or assets,
        including your personal information, in connection with a business transaction (or potential
        business transaction) such as a corporate divestiture, merger, consolidation, acquisition,
        reorganization or sale of assets, or in the event of bankruptcy or dissolution.
      </p>
      <h2>Information shared with your permission</h2>
      <p>
        Other than as set out above, you will receive notice when personal information about you
        might be shared with third parties, and you will have an opportunity to choose not to share
        the information.
      </p>
      <h2>Security of data</h2>
      <p>
        The security of your data is important to us, but no method of transmission over the
        internet or method of electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your personal information, we cannot guarantee its absolute
        security.
      </p>
      <h2>Links to other sites</h2>
      <p>
        Our Offerings may contain links to other sites that are not operated by us. If you click on
        a third party link, you will be directed to that third party's site. We strongly advise you
        to review the Privacy Policy of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any third party sites or
        services.
      </p>
      <h2>Your Choices: Access and retention of your personal information</h2>
      <p>
        You can view, update, delete, receive a copy of, restrict the use of, or restrict our use of
        your personal information. When we process personal information based on your consent, you
        have the right to withdraw consent at any time. Contact us via email, as provided below, if
        you wish to view or update your personal information or you choose to withdraw consent to
        use your personal information and close your account. We may retain certain data about you
        for legal and internal business purposes, such as fraud prevention, in accordance with
        applicable laws.
      </p>
      <p>
        We will retain your personal data for as long as necessary to provide you with the
        applications you are using, and as needed to comply with our legal obligations.
      </p>
      <p>
        <strong>Do not track</strong>. Some Internet browsers may be configured to send “Do Not
        Track” signals to the online services that you visit. We currently do not respond to “Do Not
        Track” or similar signals. To find out more about “Do Not Track,” please visit
        <a href="https://www.allaboutdnt.com"> https://www.allaboutdnt.com</a>.
      </p>

      <p>Please contact us if you need assistance to access or update your information.</p>
      <h2>Changes to this privacy policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on this page. We will let you know via email and/or a
        prominent notice on the Offerings prior to the change becoming effective and update the
        "effective date" at the top of this Privacy Policy. You are advised to review this Privacy
        Policy periodically for any changes. Changes to this Privacy Policy are effective when they
        are posted on this page.
      </p>
      <h2>Contact us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us by email at{' '}
        <a href="mailto:privacy@join.build">privacy@join.build</a>.
      </p>
    </div>
  </div>
);

export default injectSheet(styles)(Privacy);
