import collaborate from './media/collaborate.png';
import manage from './media/manage.png';
import targetValue from './media/targetValue.png';
import visualize from './media/visualize.png';

/*
PRODUCT VALUE DATA SHAPE
  sectionNameKey: {
    description: '',
    image: <img/>,
    list: [''],
    title: '',
  }
*/

export const valuesObject = {
  targetValue: {
    description:
      'Win new projects and retain repeat business by demonstrating a path to success and keeping the team focused on the budget:',
    image: targetValue,
    list: [
      'Visualize detailed project trends instantly.',
      'Present the upper and lower bounds of all potential options.',
      'See all updates in real time.',
      'View the overall project trend and zoom into recent activity.',
    ],
    title: 'See real-time cost trends',
  },
  visualize: {
    description:
      'Leverage powerful charts and reports to analyze and present real-time project costs in whichever format is best-suited for your audience:',
    image: visualize,
    list: [
      'Create reports quickly and accurately.',
      'Identify where your project is over or under budget.',
      'Focus your team’s effort in areas that will be most impactful.',
      'Customize views to present any grouping or work breakdown structure.',
    ],
    title: 'Visualize your estimate and budget',
  },
  collaboration: {
    description:
      'Integrate your stakeholders to build trust and transparency, drive team accountability, and minimize surprises:',
    image: collaborate,
    list: [
      'Share the latest and most relevant information with your entire team.',
      'Look up information in one place rather than spread across email.',
      'Assign and track action items.',
      'Control access with flexible permissions.',
    ],
    title: 'Collaboration',
  },
  manageRisk: {
    description:
      'Ditch your old spreadsheet tracking logs and upgrade your team to an interactive way to maintain your ongoing list of changes and decisions:',
    image: manage,
    list: [
      'Propose and collaborate on ideas with a single source of information.',
      'Explore and compare multiple alternatives.',
      'Tie supporting designs and cost information to every decision.',
      'See the full history and records behind every decision.',
    ],
    title: 'Manage risks & opportunities',
  },
  easeOfUse: {
    description:
      'Quickly get up and running with our easy-to-learn preconstruction platform and bring the benefits of modern, cloud-based software to your team:',
    image: undefined,
    list: [
      'Access your data anytime, from anywhere, on Windows and Mac devices.',
      'Get help with on-demand training, resources, and a responsive support team.',
      'Keep your data secure and protected.',
    ],
    title: 'Self-onboard in less than an hour',
  },
};
