import theme from '../../../../theme';

const styles = {
  containerInner: {
    display: 'block',
    margin: '0 auto',
    maxWidth: theme.presets.maxContent.maxWidth,
    textAlign: 'center',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      textAlign: 'left',
    },
  },
  containerOuter: {
    backgroundColor: theme.palette.surfaces.surface3,
    padding: '30px 0',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      padding: '30px',
    },
  },
  logos: {
    display: 'grid',
    gridGap: '32px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    paddingTop: '18px',
    '& > a': {
       cursor: 'pointer',
       placeSelf: 'center'
    },
    '& > a > img': {
      opacity: .7,
      transition: '.5s',
      width: '100px',
      '&:hover': {
        opacity: 1
      }
    },
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      '& > a > img': {
        maxHeight: '64px',
        maxWidth: '100px'
      },
    },
    [`@media all and (max-width: ${theme.breakpoints.small}px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      '& > a > img': {
        maxHeight: '48px',
        maxWidth: '100px'
      },
    },
  },
};

export default styles;
