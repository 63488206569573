import Home from './components/pages/home/Home';
import Suppliers from './components/pages/suppliers/Suppliers';
import About from './components/pages/about/About';
import Product from './components/pages/product/Product';
import ProjectTeam from './components/pages/project-team/ProjectTeam';
import News from './components/pages/news/News';
import Pricing from './components/pages/pricing/Pricing';
import Privacy from './components/pages/privacy/Privacy';
import Terms from './components/pages/terms/Terms';
import WhoWeServe from './components/pages/whoWeServe/WhoWeServe';

/* 
title     = display title.
path      = String, url.
component = React.FC, rendered page component.
exact     = Boolean, disables the partial matching for a route.
header    = Boolean, whether or not to render this route in the top navigation.
subnav    = Array of Objects; list of subnav routes 
*/

const routes = [
  {
    title: 'Home',
    path: '/home',
    component: Home,
    exact: true,
    header: true,
    subnav: undefined,
  },
  {
    title: 'Product',
    path: '/product',
    component: Product,
    exact: true,
    header: true,
    subnav: undefined,
  },
  {
    title: 'Project Team',
    path: '/project-team',
    component: ProjectTeam,
    exact: true,
    header: false,
    subnav: undefined,
  },
  {
    title: 'Suppliers',
    path: '/suppliers',
    component: Suppliers,
    exact: true,
    header: false,
    subnav: undefined,
  },
  {
    title: 'Who We Serve',
    path: '/who-we-serve',
    component: WhoWeServe,
    exact: true,
    header: true,
    subnav: [
      {
        title: 'General contractors',
        path: '/who-we-serve#general-contractors',
        component: WhoWeServe,
        exact: true,
        header: false,
      },
      {
        title: 'Owners and developers',
        path: '/who-we-serve#owners-and-developers',
        component: WhoWeServe,
        exact: true,
        header: false,
      },
      {
        title: 'Design team',
        path: '/who-we-serve#design-team',
        component: WhoWeServe,
        exact: true,
        header: false,
      },
      {
        title: 'Trade contractors',
        path: '/who-we-serve#trade-contractors',
        component: WhoWeServe,
        exact: true,
        header: false,
      },
    ],
  },
  {
    title: 'Company',
    path: '/about',
    component: About,
    exact: true,
    header: true,
    subnav: [
      {
        title: 'About Us',
        path: '/about#about',
        component: About,
        exact: true,
        header: false,
      },
      {
        title: 'Our Team',
        path: '/about#team',
        component: About,
        exact: true,
        header: false,
      },
      {
        title: "We're Hiring",
        path: '/about#hiring',
        component: About,
        exact: true,
        header: false,
      },
    ],
  },
  {
    title: 'Blog',
    path: 'https://blog.join.build/',
    external: true,
    exact: true,
    header: true,
    subnav: undefined,
  },
  {
    title: 'Contact',
    path: 'https://get.join.build/demo',
    external: true,
    exact: true,
    header: true,
    subnav: undefined,
  },
  {
    title: 'Pricing',
    path: '/pricing',
    component: Pricing,
    exact: true,
    header: false,
    subnav: undefined,
  },
  {
    title: 'News',
    path: '/news',
    component: News,
    exact: true,
    header: false,
    subnav: undefined,
  },
  {
    title: 'Privacy Policy',
    path: '/privacy',
    component: Privacy,
    exact: true,
    header: false,
    subnav: undefined,
  },
  {
    title: 'Terms',
    path: '/terms',
    component: Terms,
    exact: true,
    header: false,
    subnav: undefined,
  },
];

export default routes;
