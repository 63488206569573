import React from 'react';
import injectSheet from 'react-jss';
import UtilsGeneratedLink from './../../Utils/UtilsGeneratedLink/UtilsGeneratedLink';
import Logo from '../../logo/Logo';
import FooterStyles from './FooterStyles';
import FooterNewsletter from '../FooterNewsletter/FooterNewsletter';

const Footer = ({ classes }) => {
  const links = [
    { title: 'Home', to: '/home' },
    { title: 'Product', to: '/product' },
    { title: 'Blog', to: 'https://join.build/blog' },
    { title: 'Company', to: '/about' },
    { title: 'Careers', to: '/about#hiring' },
    { title: 'Contact Us', to: 'https://get.join.build/demo' },
    { title: 'Terms', to: '/terms' },
    { title: 'Privacy Policy', to: 'privacy' },
  ];

  return (
    <div className={classes.containerOuter}>
      <FooterNewsletter />
      <div className={classes.footer}>
        <div className={classes.footerInner}>
          <div className={classes.containerLinks}>
            <div className={classes.links}>
              {links.slice(0, 3).map((link) => {
                return <UtilsGeneratedLink key={link.to} link={link} />;
              })}
            </div>
            <div className={classes.links}>
              {links.slice(3, 6).map((link) => {
                return <UtilsGeneratedLink key={link.to} link={link} />;
              })}
            </div>
            <div className={classes.links}>
              {links.slice(6, 8).map((link) => {
                return <UtilsGeneratedLink key={link.to} link={link} />;
              })}
            </div>
          </div>

          <div className={classes.lowerFooter}>
            <Logo variant="white" className={classes.logo} />
            <div className={`${classes.copyRight}`}>
              San Francisco, CA <br /> © 2020 Join, Inc.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(FooterStyles)(Footer);
