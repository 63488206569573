import theme from '../../../theme';

const styles = {
  ...theme.presets,
  containerLinks: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      display: 'block',
      paddingBottom: '0',
    },
  },
  containerOuter: {
    backgroundColor: theme.palette.surfaces.surface1,
  },
  copyRight: {
    fontSize: 16,
    lineHeight: '30px',
  },
  footer: {
    backgroundColor: theme.palette.surfaces.surface1,
    color: theme.palette.text.onSurface1,
    padding: 30,
  },
  footerInner: {
    display: 'block',
    margin: '0 auto',
    maxWidth: theme.presets.maxContent.maxWidth,
  },
  links: {
    padding: '12px 0 45px 75px',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      padding: '6px 0 12px 0px',
    },
  },
  lowerFooter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      paddingTop: '30px',
    },
  },
};

export default styles;
