import React from 'react';
import injectSheet from 'react-jss';
import styles from './WhoWeServeStyles';
import { whoWeServeArray } from './WhoWeServeData';
import tangramLeft from './media/tangramLeft.png';
import tangramRight from './media/tangramRight.png';

const WhoWeServe = ({ classes }) => {
  const list = whoWeServeArray.map((user, index) => {
    const isEvenUser = index % 2 === 0;
    const { anchor, description, icon, list, title } = user;
    const listItems = list.map((item) => <li key={item}>{item}</li>);
    const contentArea = (
      <div id={anchor} className={classes.containerContent}>
        <h1 className={classes.title}>{title}</h1>
        {description}
        <ul className={classes.list}>{listItems}</ul>
      </div>
    );
    const iconArea = (
      <div className={classes.containerIcon}>
        <img alt={title} src={icon} />
      </div>
    );
    return (
      <div
        className={`${classes.containerListItem} ${isEvenUser && classes.containerListItemEven}`}
        key={title}
      >
        <div className={classes.containerMaxWidth}>
          {iconArea} {contentArea}
        </div>
      </div>
    );
  });
  return (
    <div>
      <div className={classes.hero}>
        <div className={classes.containerHero}>
          <img alt="join tangram background" className={classes.tangramLeft} src={tangramLeft} />
          <img alt="join tangram background" className={classes.tangramRight} src={tangramRight} />
          <h1>Who we serve</h1>
          <h3>
            Project teams are constantly under pressure to deliver timely and thoroughly vetted
            decisions. Join makes it easy for anyone to view, analyze, and propose new ideas. With
            transparent information and communication in one place, teams are empowered to
            contribute and respond to more ideas, make decisions more quickly, and optimize value
            and successful project outcomes.
          </h3>
          <a title="Learn More" rel="noopener noreferrer" href="https://get.join.build/contactus">
            <button className={classes.button}>Learn More</button>
          </a>
        </div>
      </div>
      <div>{list}</div>
    </div>
  );
};

export default injectSheet(styles)(WhoWeServe);
