import React from 'react';
import injectSheet from 'react-jss';
import HomeLogosStyles from './HomeLogosStyles';
import bartonMalow from './logos/svg/bartonMalow.svg';
import blach from './logos/svg/blach.svg';
import brasfieldGorrie from './logos/svg/brasfield.svg';
import chervenell from './logos/svg/chervenell.svg';
import clayco from './logos/svg/clayco.svg'
import danis from './logos/svg/danis.svg'
import dpr from './logos/svg/dpr.svg';
import level10 from './logos/svg/level10.svg';
import mcCarthy from './logos/svg/mccarthy.svg';
import messer from './logos/svg/messer.svg';
import shielSexton from './logos/svg/shielSexton.svg';
import swinerton from './logos/svg/swinerton.svg';
import xlConstruction from './logos/svg/xlConstruction.svg';

const Home = ({ classes }) => {
  // Note: these logos are ordered alphabetically by company name
  const logos = [
    { logo: bartonMalow, title: 'Barton Malow', url: 'https://www.bartonmalow.com/' },
    {
      logo: blach,
      title: 'Blach',
      url: 'https://blach.com/',
    },
    {
      logo: brasfieldGorrie,
      title: 'Brasfield & Gorrie',
      url: 'https://www.brasfieldgorrie.com/',
    },
    { logo: clayco, title: 'Clayco', url: 'https://claycorp.com/' },
    { logo: danis, title: 'Danis', url: 'https://www.danis.com/' },
    { logo: dpr, title: 'DPR Construction', url: 'https://www.dpr.com/' },
    {
      logo: level10,
      title: 'Level 10 Construction',
      url: 'https://www.level10gc.com/',
    },
    {
      logo: mcCarthy,
      title: 'McCarthy',
      url: 'https://www.mcCarthy.com/',
    },
    { logo: messer, title: 'Messer Construction', url: 'https://www.messer.com/' },
    { logo: shielSexton, title: 'Shiel Sexton', url: 'https://www.shielsexton.com/' },
    {
      logo: swinerton,
      title: 'Swinerton',
      url: 'https://swinerton.com/',
    },
    {
      logo: xlConstruction,
      title: 'XL Construction',
      url: 'https://www.xlconstruction.com/',
    },
  ];
  const logosPrintout = logos.map((logo) => {
    const {url, title, logo:logoImg} = logo;
    return (
      <a
        href={url}
        key={url}
        rel="noopener noreferrer"
        target="_blank"
        title={`View ${title}'s website`}
      >
        <img alt={`${title}'s logo`} src={logoImg} />
      </a>
    );
  });
  return (
    <div className={classes.containerOuter}>
      <div className={classes.containerInner}>
        <h3>Trusted by innovative builders nationwide</h3>
        <div className={classes.logos}>{logosPrintout}</div>
      </div>
    </div>
  );
};

export default injectSheet(HomeLogosStyles)(Home);
