import React from 'react';
import injectSheet from 'react-jss';
import theme from '../../../theme';
import rightTangram from './windowed-facade.svg';
import leftTangram from './brick-ivory.svg';
import option from './option.png';
import Quote from '../../quote/Quote';
import mic from './mic.png';
import ft from './ft.png';

const styles = {
  ...theme.presets,
  tangramLeft: {
    ...theme.presets.tangram,
    left: -400,
    bottom: -50
  },
  tangramRight: {
    ...theme.presets.tangram,
    right: -150,
    top: 0
  },
  headlineSection: {
    maxWidth: 582,
    margin: '0 10%',
    marginBottom: 50
  },
  inlineRight: {
    position: 'absolute',
    right: -180,
    top: 160,
    float: 'right',
    width: '55%',
    zIndex: -1
  },
  gridSection: {
    maxWidth: 582,
    margin: '0 0%',
    marginBottom: 50
  }
};

const Suppliers = ({ classes }) => (
  <div>
    <div className={classes.content}>
      <img alt="" src={leftTangram} className={classes.tangramLeft} />
      <img alt="" src={rightTangram} className={classes.tangramRight} />
      <div className={classes.headlineSection}>
        <h1>Access the right stakeholders</h1>
        <p>
          Delivery strategies, like design-build, CM at risk, and IPD bring new challenges for
          product sales and marketing: more stakeholders, amplified complexity and changes. To stay
          competitive, suppliers need to develop connections to all stakeholders in order to better
          position themselves as changes happen.
        </p>
        <a title="Learn More" rel="noopener noreferrer" href="https://get.join.build/contactus">
          <button className={classes.button}>Learn more</button>
        </a>
      </div>
    </div>
    <Quote
      photo={mic}
      companyLogo={ft}
      nameTitle="Mic Patterson, PHD, LEED AP+"
      companyName="Facade Tectonics Institute, Schüco, Enclos"
      text="Our industry is long schooled in competition, far less so in collaboration. Tools like this can help integrate collaborative workflows into our core processes."
    />
    <div className={classes.content}>
      <img alt="" src={option} className={classes.inlineRight} />
      <div className={classes.gridSection}>
        <h1>Be involved as part of the team</h1>
        <p>
          Join pulls teams together to collaboratively make decisions. Suppliers invited to the Join
          platform get greater transparency into the product evaluation process. As project teams
          collaboratively evaluate products on Join, suppliers get insights on their product
          perception and access to the right stakeholders.
        </p>
        <a title="Get involved" rel="noopener noreferrer" href="https://get.join.build/contactus">
          <button className={classes.button}>Get involved</button>
        </a>
      </div>
    </div>
  </div>
);

export default injectSheet(styles)(Suppliers);
