import theme from '../../../../theme';

const styles = {
  ...theme.presets,
  // CONTAINERS
  containerValues: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -32px',
    paddingTop: '20px',
    '& > div': {
      boxSizing: 'border-box',
      padding: '0 32px',
      width: '50%',
      [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
        width: '100%',
      },
    },
  },
  containerVideo: {
    height: 410,
    maxWidth: 500,
    minWidth: '50%',
    overflow: 'show',
    position: 'relative',
  },
  // GRID
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  // IMAGES
  imageMonitor: {
    left: 26,
    maxWidth: 400,
    position: 'absolute',
  },
  imageVideo: {
    '-webkit-transform': 'matrix3d(1,-0.01,0,-0.0003,0,1,1,0,0, 0,3,0,0,1,0,1);',
    height: 221,
    left: 30,
    position: 'absolute',
    top: 19,
    transform: 'matrix3d(1,-0.01,0,-0.0003,0,1,1,0,0, 0,3,0,0,1,0,1);',
    width: 374,
  },
};

export default styles;
