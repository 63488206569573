import video1 from './media/gif-1.gif';
import video2 from './media/gif-2.gif';
import video3 from './media/gif-3.gif';

export const generateValues = (setVideo) => [
  {
    title: 'Transparency',
    button: undefined,
    description:
      'Minimize surprises with real-time cost transparency for your entire project team. Role-based permissions provide everyone with immediate access to the most current and available information.',
    onClick: () => setVideo(video1),
  },
  {
    title: 'Accountability',
    button: undefined,
    description:
      'Know who has the ball in court on pending items at all times. Easily access a record of changes and decisions as your project progresses with full audit tracking.',
    onClick: () => setVideo(video3),
  },
  {
    title: 'Collaboration',
    button: undefined,
    description:
      'Join presents complicated information in a meaningful, easy to understand format for all stakeholders. By enabling your team to collaborate virtually, you can eliminate disconnected email threads, confusing cost tracking spreadsheets, and separate silos of project information.',
    onClick: () => setVideo(video2),
  },
  {
    title: 'Confidence',
    button: undefined,
    description:
      'Normalize your decision-making processes and reduce project risk resulting in greater confidence in cost accuracy, profitability, and project value.',
    onClick: () => setVideo(video3),
  },
  {
    title: 'Efficiency',
    button: {
      title: 'Learn More',
      url: '/product',
    },
    description:
      'Integrate your team and eliminate bottlenecks to make decisions in a timely manner. Immediately understand the impact of pending decisions with intuitive Target Value Design trendlines and project status dashboards.',
    onClick: () => setVideo(video3),
  },
];
