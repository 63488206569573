import theme from '../../../theme';

const styles = {
  link: {
    color: theme.palette.text.onSurface1,
    display: 'block',
    fontSize: 16,
    padding: 12,
    textAlign: 'right',
    textDecoration: 'none',
    textTransform: 'uppercase',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      paddingBottom: 0,
      paddingLeft: 0,
    },
  },
};

export default styles;
