import React from 'react';
import injectSheet from 'react-jss';
import twitter from '../../../media/social/twitter.svg';
import linkedin from '../../../media/social/linkedin.svg';
import FooterNewsletterStyles from './FooterNewsletterStyles';

const FooterNewsletter = ({ classes }) => (
  <div>
    <div className={classes.newsletter}>
      <div className={classes.content}>
        <div className={classes.leftColumn}>
          <h2 className={classes.headline}>Make better decisions with Join.</h2>
          <a class={classes.plain} title="Try Join on you project" rel="noopener noreferrer" href="https://get.join.build/contactus">
            {'Try Join on your project >'}
          </a>
           <div>
            <a
              aria-label="visit our linkedin"
              href="https://www.linkedin.com/company/joindotbuild/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.social}
            >
              <img src={linkedin} alt="" />
            </a>
            <a
              aria-label="visit our twitter"
              href="https://twitter.com/joindotbuild"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.social}
            >
              <img src={twitter} alt="" />
            </a>
          </div>
        </div>
        <div className={classes.rightColumn}>
          <a title="Learn More" rel="noopener noreferrer" href="https://get.join.build/subscribe" target="_blank">
            <button className={classes.button}>Subscribe</button>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default injectSheet(FooterNewsletterStyles)(FooterNewsletter);
