import React from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import routes from './routes';
import NavlinkBar from './components/NavLink/NavLinkBar/NavLinkBar';
import Footer from './components/footer/Footer/Footer';
import Home from './components/pages/home/Home';

const title = 'Join';

const App = () => (
  <div>
    <Helmet titleTemplate={`%s - ${title}`} />
    <NavlinkBar />
    <Switch>
      {/*GENERIC PAGE ROUTES*/}
      <Route path="/" exact component={Home} />
      {routes.map((route, i) => (
        <Route key={i} {...route} />
      ))}
      {/*LOGIN PAGE CONFUSED USER REDIRECT*/}
      <Route exact path="/login" render={() => (window.location = 'https://app.join.build/')} />
      {/*UNKNOWN ROUTE REDIRECT TO HOME PAGE*/}
      <Redirect from="*" to="/" />
    </Switch>
    <Footer />
  </div>
);

export default App;
