import React from 'react';
import injectSheet from 'react-jss';

import theme from '../../../theme';

const styles = {
  ...theme.presets,
  headlineSection: {
    maxWidth: 582,
    margin: '0 10%',
  },
};

const Terms = ({ classes }) => (
  <div>
    <div className={classes.content}>
      <h1>Terms of Service</h1>
      <div>Effective Date: 1 September 2020</div>

      <ol data-rte-list="default">
        <li>
          <p>
            <strong>AGREEMENT.</strong> &nbsp;Please read these terms of service (“Agreement” or
            “Terms”) carefully. This Agreement is legally binding and sets forth the terms and
            conditions between You and Join, Inc. (“Join”) for the use of Join websites, customer
            support, services, sample files, scripts, printable materials, electronic documentation
            and/or any updates thereof (“Services”).
          </p>
          <p>
            <strong>
              You acknowledge and hereby agree that Your access to the Services is explicitly
              conditioned on a Join customer who authorizes you to use the Services (“Customer”)
              entering into a separate agreement with Join, that Customer’s rights and subscription
              to the Services, and that Customer designating You as one of their “Authorized
              User(s)”. By accessing the Services and/or clicking “I agree”, You acknowledge that
              you have read, understand, and agree to be bound by these Terms and you represent that
              you have the authority to enter into this agreement personally, or on behalf of the
              company or other legal entity for which you are acting (in either case, “You” or
              “Your”). If You do not agree to these Terms or do not have such authority, You may not
              access or use the Services.
            </strong>
          </p>
          <p>
            The Services may include third party offerings including software, services, content,
            documents or similar material governed by additional terms and conditions (“Third-Party
            Terms ”). Such Third-Party Terms are referenced on the Join website and, by accessing
            the Services, You are also accepting the Third-Party Terms, if any, set forth therein.
            If there are any conflicts between these Terms and the Third-Party Terms in relation to
            their subject matter, the Third-Party Terms will prevail. You may have an additional
            agreement signed directly with Join that supplements or amends these Terms, including
            (“Supplemental Terms ”). You agree to these Supplemental Terms, if any, for any Services
            that You subscribe to, obtain, access or use. If You do not agree to all such
            Supplemental Terms, You may not access or use the Services. If there is any conflict
            between these Terms and the Supplemental Terms, the Supplemental Terms will control in
            relation to their subject matter.
          </p>
        </li>
        <li>
          <p>
            <strong>ADMINISTRATORS.</strong> &nbsp;Customers may designate project administrators
            (“Administrator(s)”) who can control certain Customer account settings, including Your
            access and use of the Services and permissions. You hereby grant such Customer and the
            Administrator permission to access, use, download, export, disclose, share, restrict
            and/or remove Your Content (defined below). You also acknowledge and agree that a
            Customer or Administrator may restrict or terminate your access to your account, Your
            Content or the Services. Upon termination of the applicable Customer’s rights to use the
            Services, your use of the Services will automatically terminate.
          </p>
        </li>
        <li>
          <p>
            <strong>PRIVACY.</strong> &nbsp;Information on how Join collects, uses, shares, or
            processes information about You can be found on the{' '}
            <a href="https://join.build/privacy">Privacy Policy</a> link on the Join website.
          </p>
        </li>
        <li>
          <p>
            <strong>ACCOUNT.</strong> &nbsp;If you are invited by a Customer to use the Services,
            You may need to create an account. You are responsible for the security of Your account
            and all activity associated with it. You will not share account information or passwords
            (and will ensure that Your account information is, and continues to be, true and
            complete. Please notify Join Customer Support at{' '}
            <a href="mailto:support@join.build">support@join.build</a> immediately if You become
            aware of any unauthorized use of Your account.
          </p>
        </li>
        <li>
          <p>
            <strong>YOUR CONTENT.</strong> &nbsp;You retain all rights and ownership of any
            electronic files, designs, models, images, or similar material (“Content”) created by
            You and submitted or uploaded into the Services. Join may create reasonable technical
            limits on file size, storage space, processing capacity, and other technical limits on
            Content. Join will not use Your Content except (a) at Your request, or with Your
            consent, (b) in connection with providing and improving Services, (c) in connection with
            legal obligations, enforcement, investigations or proceedings, or (d) as permitted under
            our agreement with Customer. Join reserves the right to screen or review Content and may
            block or remove Content for any reason including noncompliance with these Terms. When
            You provide Content, You authorize Join and its designees the right to use, reproduce,
            display, distribute, modify, and translate the Content as needed for the purpose of
            operating or improving the Service. Services may provide features that allow You to
            share Your Content with other Customer authorized users or to make it public. Join does
            not control what others do with Your Content and You are responsible for the Content
            that You share. Once You share or publish Your Content, suspension or termination of
            your access to the Services will not delete or inhibit access to any of Your Content
            that was earlier copied, transferred or otherwise shared or published. Services may
            provide features that allow You and other Customer authorized users to comment on
            Content. Your comments may be deleted by You, other Customer authorized users, or Join.
          </p>
        </li>
        <li>
          <p>
            <strong>USE OF SERVICES.</strong> &nbsp;You acknowledge and agree that Your access and
            use of the Services are subject to Customer’s rights and subscription to the Services.
            Additionally, You will access and use the Services in conformance with all applicable
            laws. Except as expressly authorized by these Terms, or as otherwise expressly permitted
            in writing by Join, You will not (a) remove, disable or otherwise limit the
            effectiveness of any technical protection used by Join to (i) manage, monitor, control
            or analyze the access to, or use of, any of the Services or (ii) protect Join’s
            intellectual property rights; (b) post, transmit or otherwise make available using the
            Services any information or material that is or may be (i) false, libelous, defamatory,
            fraudulent or otherwise unlawful or tortious; (ii) threatening, harassing, degrading,
            hateful or intimidating, or that otherwise fail to respect the rights and dignity of
            others; (iii) obscene, indecent, pornographic or otherwise objectionable; (iv) protected
            by copyright, trademark, design rights, trade secret rights, right of publicity or
            privacy, or any other proprietary right, without the express prior written consent of
            the applicable owner; (v) a national or state secret, classified information or any
            other information or material (including any photograph, drawing, plan or model) that is
            subject to official confidentiality treatment; (vi) secret codes, countersigns,
            crypto-currency, passwords or other similar information; (vii) advertising, spam, an
            offer to sell or buy any goods or services, a “chain letter” or any other form of
            solicitation; or (viii) any malware (such as a virus, worm, Trojan horse, Easter egg,
            time bomb or spyware) or other computer code, file or program that is or is potentially
            harmful or invasive or intended to damage or hijack the operation of, limit the use of,
            or monitor the use of, any hardware, software or equipment; (c) use the Services in any
            way that is fraudulent or otherwise unlawful or tortious, or has any fraudulent or other
            unlawful or tortious purpose or effect; (d) interfere with or disrupt the operation of
            the Services or the servers or networks used to make any of the Services available,
            including by hacking or defacing any portion of the Services; (e) attempt to probe, scan
            or test the vulnerability of the Services or to breach or circumvent any security or
            authentication measures used by the Services; (f) use the Services as storage for
            “remote loading” or as a “door” or “signpost” to other web pages or internet resources,
            whether inside or beyond the sites through which the Services are provided; (g)
            impersonate any other individual or entity or falsely state or otherwise misrepresent
            Your affiliation with any person or entity; (h) use the Services in connection with any
            inherently dangerous application, including any application that could result in death,
            personal injury, catastrophic damage or mass destruction; (i) collect Content or
            information, from or with the Services, using any means (including robots, spiders, site
            search/retrieval applications or other devices to retrieve, index, “scrape,” or “data
            mine”); (j) use the Services in connection with the training of a neural network or
            machine learning, deep learning or artificial intelligence system or software; or (k)
            unbundle the component parts of the Services for separate use.
          </p>
        </li>
        <li>
          <p>
            <strong>COMPANY PROPRIETARY RIGHTS.</strong> &nbsp;The rights granted to You in this
            Agreement are subject to the following restrictions: (a) You shall not license, sell,
            rent, lease, transfer, assign, distribute, host, outsource, disclose or otherwise
            commercially exploit the Services or make the Services available to any third party; (b)
            You shall not modify, make derivative works of, disassemble, reverse engineer or
            otherwise attempt to discover, learn or study the structure or organization, underlying
            algorithms or other internals, protocols, data structures or the source code of the
            Services; (c) You shall not access the Services in order to build a similar or
            competitive product or service; (d) except as expressly stated herein, no part of the
            Services may be copied, reproduced, distributed, republished, downloaded, displayed,
            posted or transmitted in any form or by any means, including but not limited to
            electronic, mechanical, photocopying, recording or other means; and (e) any future
            release, update, or other addition to functionality of the Services shall be subject to
            the terms of this Agreement, unless Join expressly states otherwise. &nbsp;Neither Join
            nor any of its suppliers is obligated to provide any services, updates or upgrades to
            the Services. You shall preserve all copyright and other proprietary rights notices in
            the Services.
          </p>
        </li>
        <li>
          <p>
            <strong>OWNERSHIP. </strong>&nbsp;All right, title, and interest, including all
            intellectual property rights, in and to the Services or other materials provided or made
            available by Join to You shall be owned and retained by Join or its suppliers. &nbsp;Any
            rights not expressly granted by Join in this Agreement are reserved. You acknowledge
            that You have only the rights expressly granted under these Terms and that any rights
            not expressly granted are reserved by Join.
          </p>
        </li>
        <li>
          <p>
            <strong>CONSENT TO USE OF DATA.</strong> You agree that, notwithstanding any obligation
            or restriction to the contrary (including any restriction in any confidentiality
            agreement between the parties), Join may collect and use technical and usage data
            regarding Your use of the Services or any related service provided by Join. Join may use
            this data to improve its products and services and to provide customized services to
            You.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>DISCLAIMER OF WARRANTIES.</strong> &nbsp;THE SERVICES AREPROVIDED TO YOU ON AN
            “AS-IS” BASIS. &nbsp;COMPANY AND ITS SUPPLIERS DISCLAIM ALL EXPRESS, IMPLIED OR
            STATUTORY WARRANTIES RELATING TO THE SERVICES, INCLUDING BUT NOT LIMITED TO,
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
            &nbsp;COMPANY DOES NOT WARRANT THAT USE OF THE SERVICES WILL BE UNINTERRUPTED, SECURE,
            ACCURATE, RELIABLE OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE
            SERVICESARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT COMPANY’S SECURITY
            PROCEDURES AND MECHANISMS WILL PREVENT THELOSS OR ALTERATION OF, OR IMPROPER ACCESS TO,
            PERSONAL INFORMATION OR CONTENT.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>LIMITATION OF REMEDIES AND DAMAGES.</strong> &nbsp;TO THE MAXIMUM EXTENT
            PERMITTED BY LAW, NEITHER COMPANY NOR ITS SUPPLIERS SHALL BE RESPONSIBLE OR LIABLE WITH
            RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS OR CONDITIONS RELATED THERETO
            UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY (A) FOR LOSS OR
            INACCURACY OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY,
            OR (B) FOR ANY INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES INCLUDING, BUT NOT LIMITED
            TO LOSS OF REVENUES AND LOSS OF PROFITS. TO THE MAXIMUM EXTENT PERMITTED BY LAW,
            COMPANY’S AGGREGATE CUMULATIVE LIABILITY HEREUNDER SHALL NOT EXCEED THE GREATER OF FIFTY
            DOLLARS ($50.00) OR THE REMEDY OR PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM
            ARISES. THIS SECTION SHALL NOT APPLY TO LIABILITY OF COMPANY FOR (A) DEATH OR PERSONAL
            INJURY CAUSED BY COMPANY’S NEGLIGENCE; OR (B) ANY INJURY CAUSED BY A COMPANY’S FRAUD OR
            FRAUDULENT MISREPRESENTATION.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>BASIS OF BARGAIN.</strong> &nbsp;The warranty disclaimer and limitation of
            liability set forth above are fundamental elements of the basis of the agreement between
            Join and You. &nbsp;Join would not be able to provide the Services on an economic basis
            without such limitations. &nbsp;The warranty disclaimer and limitation of liability
            inure to the benefit of Join’s suppliers.
          </p>
        </li>
        <li>
          <p>
            <strong>TERM AND TERMINATION.</strong> &nbsp;This Agreement is effective on the date a
            Customer provides You authorization to access the Services and shall continue until: (i)
            such Customer (or an Administrator) revokes your authorization, (ii) Customer’s rights
            and subscription to the Services terminates or expires, or (iii) Your access to the
            Services is terminated by Join. Notwithstanding anything to the contrary in these Terms,
            Join may terminate this Agreement and Your access to the Services immediately upon
            notice to You in the event that (a) You materially breach any of the Terms hereof or if
            (b) Customer materially breaches any of the terms of their agreement with Join. If in
            good faith Join believes that You or Your Content pose a security risk that may impact
            Join, our customers, or authorized users, or subject Join, our customers, or authorized
            users to any liability, or fail to comply with these Terms, Join reserves the right, at
            its sole discretion, to terminate or suspend Your access to Services. Unless Join
            reasonably determines that immediate action is prudent, Join will seek to notify You of
            Your pending suspension or termination before it takes effect. You will immediately stop
            using the Services, and Your right to access and use the Services will end upon the
            expiration or termination of Customer’s rights and subscription to the Services, or
            expiration or termination of this Agreement. Sections 5 through 18 will survive
            termination of these Terms for any reason.
          </p>
        </li>
        <li>
          <p>
            <strong>CONFIDENTIALITY.</strong> You or Join (as the “Disclosing Party”) may disclose
            or make available information regarding Your or Join technology, products or business
            (“Confidential Information”), whether written, oral or graphic, to the other party (as
            the “Receiving Party”) in connection with these Terms. The Receiving Party will use the
            same degree of care as to the Disclosing Party’s Confidential Information that it uses
            to protect the confidentiality of its own confidential information of like kind (but in
            no event less than reasonable care) and will (a) use the Confidential Information of the
            Disclosing Party only in connection with Services, and (b) except as otherwise
            authorized by the Disclosing Party in writing, limit access to the Confidential
            Information of the Disclosing Party to those of its employees, consultants, contractors,
            service providers, professional advisors and other individuals who need such access for
            purposes related to Services and who are subject to confidentiality obligations with the
            Receiving Party no less stringent than those in these Terms. The Receiving Party may
            disclose Confidential Information of the Disclosing Party if it is compelled by law to
            do so. The Receiving Party will give the Disclosing Party prior notice of such compelled
            disclosure (to the extent legally permitted) and take reasonable steps to limit such
            disclosure. Join may also disclose Your Confidential Information to comply with any
            governmental or regulatory body request (including subpoenas or court orders), as part
            of a legal proceeding involving Join, or at Your request. If disclosure is made at Your
            request, You may be responsible for the costs of compiling and providing access to Your
            Confidential Information.
          </p>
        </li>
        <li>
          <p>
            <strong>MODIFICATIONS.</strong> &nbsp;Join reserves the right to change the terms and
            conditions of this Agreement or its policies relating to the Services at any time. Join
            will notify You of any material changes to this Agreement by sending an e-mail to
            Customer who authorized Your access to the Services or by prominently posting notice of
            the changes on the Join website. Any material changes to this Agreement will be
            effective upon the earliest of thirty (30) calendar days following dispatch of e-mail
            notice to Customer or thirty (30) calendar days following posting of notice of the
            changes on the Join website. These changes will be effective immediately for new
            authorized users of the Services. If You do not agree with the changes to this
            Agreement, You must not continue to use the Services. Continued use of the Services,
            following notice of such changes, shall indicate Your acknowledgement of such changes
            and agreement to be bound by the terms and conditions of such changes.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>EXPORT.</strong> &nbsp;The Services and related technology are subject to United
            States export control laws and may be subject to export or import regulations in other
            countries. &nbsp;You agree not to export, reexport, or transfer, directly or indirectly,
            any U.S. technical data acquired from Join, or any products utilizing such data, in
            violation of the United States export laws or regulations. You must not access or use
            any Services from within a U.S. sanctioned location or if You appear on any U.S.
            government restricted parties list. You will indemnify and hold Join harmless from any
            and all claims, losses, liabilities, damages, fines, penalties, costs and expenses
            (including attorney’s fees) arising from or relating to any breach by You of its
            obligations under this section. &nbsp;Your obligations under this section shall survive
            the expiration or termination of this Agreement.
          </p>
        </li>
        <li>
          <p>
            <strong>U.S. GOVERNMENT LICENSE RIGHTS.</strong> All Services provided to the U.S.
            Government are provided with the commercial license rights and restrictions described
            herein.{' '}
          </p>
        </li>
        <li>
          <p>
            <strong>MISCELLANEOUS.</strong> &nbsp;Neither the rights nor the obligations arising
            under this Agreement are assignable by You, and any such attempted assignment or
            transfer shall be void and without effect. This Agreement shall be governed by and
            construed in accordance with the laws of the State of California and the United States
            without regard to the conflict of law’s provisions therein that would require
            application of the laws of another jurisdiction. Any action under or relating to this
            Agreement shall be brought solely in the state and federal courts located in California
            with sole venue in the courts located in San Francisco County and each party hereby
            submits to the personal jurisdiction of such courts, except that Join may seek relief in
            any court of competent jurisdiction to protect or enforce its intellectual property and
            proprietary rights. The United Nations Convention on Contracts for the International
            Sale of Goods shall not apply to this Agreement. In the event that any provision of this
            Agreement is found to be contrary to law, then such provision shall be construed as
            nearly as possible to reflect the intention of the parties, with the other provisions
            remaining in full force and effect. Any notice to You may be provided by e-mail to You
            and/or Customer. No failure to exercise, and no delay in exercising, on the part of
            either party, any right or any power hereunder shall operate as a waiver thereof, nor
            shall any single or partial exercise of any right or power hereunder preclude further
            exercise of any other right hereunder. This Agreement constitutes the entire agreement
            between the parties pertaining to the subject matter hereof, and any and all written or
            oral agreements previously existing between the parties are expressly canceled.{' '}
          </p>
        </li>
      </ol>
    </div>
  </div>
);

export default injectSheet(styles)(Terms);
