import theme from '../../../theme';

const styles = {
  containerOuter: {
    background: theme.palette.surfaces.surface4,
    border: `2px solid ${theme.palette.border}`,
    color: theme.palette.text.onSurface4,
    cursor: 'pointer',
    position: 'absolute',
    top: '64px',
    width: '230px',
    '& > a': {
      color: theme.palette.text.onSurface4,
      cursor: 'pointer',
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'left',
      textDecoration: 'none',
      textTransform: 'uppercase',
      [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
        fontSize: 20,
      },
      '&:hover, &:active, &:focus': {
        background: theme.palette.backgroundGrey,
      },
    },
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      background: theme.palette.surfaces.surface2,
      border: 'none',
      margin: 0,
      position: 'relative',
      top: -2,
      width: '100%',
      '& > a': {
        color: theme.palette.text.onSurface4,
        padding: 16,
        '&:hover, &:active, &:focus': {
          background: theme.palette.backgroundGrey,
        },
        textAlign: 'center',
      },
    },
  },
  link: {
    background: theme.palette.surfaces.surface4,
    border: 'none',
    cursor: 'pointer',
    padding: 12,
    width: '100%',
  },
};

export default styles;
