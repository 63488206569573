import React from 'react';
import injectSheet from 'react-jss';
import logo from './logo.svg';
import wordmark from './wordmark.svg';
import whiteLogo from './logo-white.png';
import { Link } from 'react-router-dom';

const styles = {
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 81,
    width: 74,
  },
  white: {
    height: 81,
    width: 74,
  },
  wordmark: {
    width: 74,
    height: 50,
  },
};

const variants = {
  logo: logo,
  white: whiteLogo,
  wordmark: wordmark,
};

const Logo = ({ classes, variant }) => (
  <Link className={classes.link} title="home" to="/">
    <img src={variants[variant] || logo} className={classes[variant]} alt="Join logo" />
  </Link>
);

Logo.defafultProps = {
  variant: 'logo',
};

export default injectSheet(styles)(Logo);
