import React from 'react';
import injectSheet from 'react-jss';
import HomeStyles from './HomeStyles';
import Testimonials from './../../Testimonials/Testimonials';
import leftTangram from './tangram-wood.svg';
import rightTangram from './windowed-facade.svg';
import HomeLogos from './HomeLogos/HomeLogos';
import HomeValueProposition from './HomeValueProposition/HomeValueProposition';
import { testimonialsData } from '../../Testimonials/TestimonialsData';
const Home = ({ classes }) => {
  const { kellyOrtiz, jeffGood } = testimonialsData;
  return (
    <div>
      <div className={`${classes.content} ${classes.hero}`}>
        <img alt="" src={leftTangram} className={classes.tangramLeft} />
        <img alt="" src={rightTangram} className={classes.tangramRight} />
        <div className={classes.headlineSection}>
          <h1>Preconstruction collaboration software</h1>
          <p>
          The Join platform connects project teams and their data to improve collaborative decision making when it can most positively impact your project outcome.
          </p>
          <a title="Get a demo" rel="noopener noreferrer" target="_blank" href="https://get.join.build/demo">
            <button className={classes.button}>Get a demo</button>
          </a>
        </div>
      </div>
      <HomeLogos />
      <HomeValueProposition />
      <Testimonials testimonialsList={[kellyOrtiz, jeffGood]} />
      <div className={classes.valuePropSection}>
        <div className={classes.row}>
          <h2>Join helps the entire project team</h2>
          <p>
            Join is used by contractors, designers, owners and suppliers on any project type,
            delivery method, or project phase. Regardless of whether optimization for building
            decisions happens as an explicit value engineering phase or as an ongoing part of design
            and preconstruction collaboration, Join streamlines that process, enabling it to happen
            earlier when it can have the most beneficial impact.
          </p>
        </div>
        <a title="Learn More" rel="noopener noreferrer" href="https://get.join.build/contactus">
          <button className={classes.button}>Learn More</button>
        </a>
      </div>
    </div>
  );
};

export default injectSheet(HomeStyles)(Home);
