import React from 'react';
import injectSheet from 'react-jss';
import { HashLink } from 'react-router-hash-link';
import rightTangram from './media/blue-white-facade-1.svg';
import leftTangram from './media/colorful-2.svg';
import hiring from './media/hiring.jpg';
import AboutBio from './AboutBio/AboutBio';
import AboutStyles from './AboutStyles';
import { teamList } from './AboutTeamData';

class About extends React.Component {
  render() {
    const { classes } = this.props;
    const teamPrintout = teamList.map((teamMember) => {
      const { name, title, photo, link } = teamMember;
      return <AboutBio key={name} name={name} title={title} photo={photo} linked={link} />;
    });
    return (
      <div>
        <div id="about" className={`${classes.hiringContent} ${classes.containerContent}`}>
          <img alt="" src={rightTangram} className={classes.tangramLeft} />
          <img alt="" src={leftTangram} className={classes.tangramRight} />
          <div className={classes.headlineSection}>
            <h1>About Us</h1>
            <p>
              Our mission is to enable teams to fulfill the potential of every construction project. We believe this can only be accomplished through transparent
              collaboration among all critical stakeholders in the decision-making process. We're a
              virtual-first company primarily based in San Francisco and backed by leading venture
              capital and angel investors.
            </p>
            <HashLink title="We're hiring" to="#hiring">
              <button className={classes.button}>We're Hiring</button>
            </HashLink>
          </div>
        </div>
        <div id="team" className={`${classes.maxContent} ${classes.containerContent}`}>
          <h1 className={classes.teamTitle}>Our Team</h1>
          <div className={classes.row}>{teamPrintout}</div>
        </div>
        <div id="hiring" className={`${classes.innerContent} ${classes.containerContent}`}>
          <img className={classes.image} alt={"We're Hiring."} src={hiring} />
          <h1>We're Hiring</h1>
          <p>
            Join is a small team tackling big problems in the built environment with software. We
            see a large opportunity in front of us to positively impact the built environment in
            which we live, work and play. We are hiring for several roles and would love to hear
            from you.
          </p>
          <p>
            Know that you could bring something special to the team and mission? Send a resume and
            cover letter to{' '}
            <strong>
              <a className={classes.link} href="mailto:hello@join.build">
                hello@join.build
              </a>
            </strong>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default injectSheet(AboutStyles)(About);
