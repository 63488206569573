import theme from '../../../theme';

const styles = {
  notActive: {
    border: '2px solid transparent',
  },
  button: {
    alignItems: 'center',
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: 12,
    whiteSpace: 'nowrap',
    width: '100%',
    '&:focus': {
      background: theme.palette.surfaces.surface4,
      border: `2px solid ${theme.palette.border}`,
      borderRadius: 0,
      color: theme.palette.text.onSurface4,
      outline: 'none',
      padding: '10px',
      [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
        alignItems: 'center',
        background: theme.palette.surfaces.surface5,
        border: `1px solid ${theme.palette.border}`,
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
        outline: 'none',
        padding: 16,
      },
    },
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      padding: 0,
    },
    '& > img': {
      height: 20,
      position: 'relative',
      top: '-2px',
      transform: 'translateY(5%)',
      width: 20,
    },
    '& > span': {
      transform: 'translateY(5%)',
    },
  },
  linkText: {
    color: theme.palette.primary,
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: 10,
    position: 'relative',
    top: -2,
    width: '100%',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      fontSize: 20,
      padding: 16,
    },
  },
  root: {
    alignItems: 'center',
    color: theme.palette.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 12,
    '& > span': {
      width: '100%',
    },
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      padding: 0,
    },
  },
  subnav: {
    padding: '10px 0',
  },
  active: {
    borderWidth: '2px 0 0 0',
    padding: '10px 12px 12px 12px',
    borderTop: theme.border.generic,
  },
  highlightedButton: {
    background: theme.palette.surfaces.surface4,
    border: `2px solid ${theme.palette.border}`,
    borderRadius: 0,
    color: theme.palette.text.onSurface4,
    padding: '10px',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      alignItems: 'center',
      background: theme.palette.surfaces.surface5,
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      padding: 16,
    },
  },
};
export default styles;
