import theme from '../../../theme';

const styles = {
  close: {
    background: 'none',
    border: 'none',
    padding: 22,
    '& > img': {
      cursor: 'pointer',
      height: 42,
      width: 42,
    },
  },
  icon: {
    cursor: 'pointer',
    height: 36,
    padding: 8,
    width: 36,
  },
  iconHamburger: {
    background: 'none',
    border: 'none',
  },
  menu: {
    backgroundColor: 'white',
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
  },
  menuClosed: {
    '-webkit-transition': 'height 1s ease',
    display: 'none',
    height: 0,
    overflow: 'hidden',
    transition: 'height 1s ease',
  },
  menuOpen: {
    '-webkit-transition': 'height 1s ease',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary}`,
    borderBottom: `1px solid ${theme.palette.primary}`,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'height 1s ease',
  },
  scrolling: {
    top: 79,
  },
};

export default styles;
