import theme from '../../../theme';

const styles = {
  ...theme.presets,
  containerContactSales: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.presets.maxContent.maxContentWidth,
    padding: '64px',
    width: '100%',
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      padding: '64px 32px',
    },
    '& > p': {
      fontSize: 22,
      marginBottom: '-8px',
    },
  },
  containerContactSalesWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  heroCopy: {
    ...theme.presets.content,
    maxWidth: '660px',
    padding: '230px 32px 60px 32px',
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      padding: '230px 32px 72px 32px',
    },
  },
  tangramLeft: {
    left: 0,
    maxWidth: '223px',
    position: 'absolute',
    top: 0,
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      display: 'none',
    },
  },
  tangramRight: {
    position: 'absolute',
    right: 0,
    top: '349px',
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      display: 'none',
    },
  },
};

export default styles;
