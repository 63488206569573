import React from 'react';
import injectSheet from 'react-jss';
import NavLink from '../NavLink/NavLink';
import routes from '../../../routes';
import styles from './NavLinkListStyles';

const NavLinkList = ({ classes }) => {
  return (
    <div className={classes.root}>
      {routes
        .filter((r) => r.header)
        .map((route, i) => {
          return <NavLink key={i} onClick={() => {}} {...route} />;
        })}
    </div>
  );
};

export default injectSheet(styles)(NavLinkList);
