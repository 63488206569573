import theme from '../../../theme';

const styles = {
  hiddenSmall: {
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      display: 'none',
    },
  },
  myLabel: {
    fontStyle: 'italic',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
  },
  shownSmall: {
    [`@media all and (min-width: ${theme.breakpoints.medium + 1}px)`]: {
      display: 'none',
    },
  },
  sticky: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  stuck: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 3px ${theme.palette.primary}`,
  },
  ...theme.presets,
};

export default styles;
