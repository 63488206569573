import React from 'react';
import injectSheet from 'react-jss';
import styles from './ProductStyles';
import tangramLeft from './media/tangramLeft.png';
import tangramRight from './media/tangramRight.png';
import ProductValueSection from './ProductValueSection/ProductValueSection';
import { valuesObject } from './ProductData';
import ProductEaseOfUse from './ProductEaseOfUse/ProductEaseOfUse';

const Product = ({ classes }) => {
  const { collaboration, manageRisk, targetValue, visualize } = valuesObject;
  return (
    <div>
      {/* HERO */}
      <div>
        <img alt="tangram background" className={classes.tangramRight} src={tangramRight} />
        <img alt="tangram background" className={classes.tangramLeft} src={tangramLeft} />
        <div className={classes.heroCopy}>
          <h1>The #1 preconstruction platform for project teams</h1>
          <a title="Get a demo" rel="noopener noreferrer" target="_blank" href="https://get.join.build/demo">
            <button className={classes.button}>Schedule a Demo</button>
          </a>
        </div>
      </div>
      <ProductValueSection
        hasGrayBackground
        imageOnLeft
        sectionTitle="Understand and present your project trends"
        values={[targetValue, visualize]}
      />
      <ProductValueSection
        hasGrayBackground={false}
        imageOnLeft={false}
        sectionTitle="Evaluate and guide your preconstruction decisions"
        values={[collaboration, manageRisk]}
      />
      <ProductEaseOfUse />
      <div className={classes.containerContactSalesWrapper}>
        <div className={classes.containerContactSales}>
          <p>Ready to learn how Join can help you better understand and guide your project?</p>
          <a title="Get a demo" rel="noopener noreferrer" target="_blank" href="https://get.join.build/demo">
            <button className={classes.button}>Schedule a Demo</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(styles)(Product);
