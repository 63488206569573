import dawson from './photos/dawson.png';
import derek from './photos/derek.png';
import kelly from './photos/kelly.jpg';
import jeff from './photos/jeff.png';

/*
TESTIMONIAL DATA SHAPE

  nameKey: {
    bio: '',
    copy: '',
    photo: <img/>
  },
*/

export const testimonialsData = {
  kellyOrtiz: {
    bio: 'Kelly Ortiz, Director of Estimating McCarthy Building Companies, Inc.',
    copy:
      'Join has helped focus our conversations with the client around how we can improve the project outcome as a team.',
    photo: kelly,
  },
  jeffGood: {
    bio: 'Jeff Good, Director of Preconstruction Swinerton.',
    copy: 'The owner absolutely loves it.',
    photo: jeff,
  },
  dawsonJones: {
    bio: 'Dawson Jones, Estimating Manager, Swinerton Builders',
    copy:
      'I haven’t found a thing that I couldn’t do in Join. Join is an extremely user-friendly program!',
    photo: dawson,
  },
  derekFitzgerald: {
    bio: 'Derek Fitzgerald, Preconstruction Director, McCarthy Building Companies, Inc.',
    copy:
      'Join has helped ensure that ideas from different parties get tracked in the conversation immediately, improving accountability and prioritization.',
    photo: derek,
  },
};
