import React from 'react';
import injectSheet from 'react-jss';
import styles from './ProductEaseOfUseStyles';
import { testimonialsData } from '../../../Testimonials/TestimonialsData';
import Testimonials from '../../../Testimonials/Testimonials';
import { valuesObject } from '../ProductData';
const ProductEaseOfUse = ({ classes }) => {
  const { title, description, list } = valuesObject.easeOfUse;
  const { dawsonJones, derekFitzgerald } = testimonialsData;

  const listPrintout = list.map((item) => <li key={item}>{item}</li>);
  const content = (
    <div>
      <h3>{title}</h3>
      <p>{description}</p>
      <ul className={classes.list}>{listPrintout}</ul>
    </div>
  );

  return (
    <div className={classes.containerOuter}>
      <h1 className={classes.sectionTitle}>Ease of use for the entire project team</h1>
      <div className={classes.containerValue}>
        <div className={classes.containerValueInner}>
          <div className={classes.containerTestimonials}>
            <Testimonials testimonialsList={[dawsonJones, derekFitzgerald]} />
          </div>
          <div className={classes.containerContent}>{content}</div>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(styles)(ProductEaseOfUse);
