import React from 'react';
import injectSheet from 'react-jss';

import { Route } from 'react-router-dom';
import styles from './NavLinkSubnavStyles';
import { generateFormatedLinks } from './../../Utils';
import UtilsGeneratedLink from './../../Utils/UtilsGeneratedLink/UtilsGeneratedLink';

const NavLinkSubnav = ({ classes, subnav, showSubnav, closeSubnav }) => {
  const { path, exact } = subnav;

  const formattedLinks = generateFormatedLinks(subnav);
  const subnavLinks = subnav.map((subnavLink, index) => {
    const formattedLink = formattedLinks[index];
    return <UtilsGeneratedLink key={subnavLink.path} link={formattedLink} />;
  });
  const subnavPrintout = showSubnav && (
    <Route
      children={({ match }) => (
        <div
          onClick={() => {
            closeSubnav();
          }}
          className={classes.containerOuter}
        >
          {subnavLinks}
        </div>
      )}
      exact={exact}
      path={path}
    />
  );
  return subnavPrintout;
};

export default injectSheet(styles)(NavLinkSubnav);
