import React from 'react';
import injectSheet from 'react-jss';
import theme from '../../../theme';

const styles = {
  bigText: theme.presets.bigText,
  card: {
    maxWidth: 400,
    width: 370,
    position: 'relative',
    margin: 12,
    textDecoration: 'none',
    color: theme.palette.primary
  },
  snippet: {
    paddingRight: 24,
    height: '2rem',
    overflow: 'ellipsis'
  },
  image: {
    width: '100%'
  }
};

const PostCard = ({ classes, title, photo, url, text }) => (
  <a className={classes.card} href={url}>
    <img className={classes.image} alt={title} src={photo} />
    <h3>{title}</h3>
  </a>
);

export default injectSheet(styles)(PostCard);
