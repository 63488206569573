import React from 'react';
import injectSheet from 'react-jss';

import posts from './posts';
import theme from '../../../theme';
import rightTangram from './cityscape.svg';
import leftTangram from './woven-facade.svg';
import PostCard from './PostCard';

const styles = {
  ...theme.presets,
  tangramLeft: {
    ...theme.presets.tangram,
    left: -640,
    top: -32
  },
  tangramRight: {
    ...theme.presets.tangram,
    right: -108,
    top: -197
  },
  headlineSection: {
    maxWidth: 582,
    margin: '0 10%',
    marginBottom: 50
  },
  articles: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
};

const News = ({ classes }) => (
  <div>
    <div className={classes.content}>
      <img alt="" src={leftTangram} className={classes.tangramLeft} />
      <img alt="" src={rightTangram} className={classes.tangramRight} />
      <div className={classes.headlineSection}>
        <h1>News</h1>
        <p>.., product updates, and more from the Join team.</p>
      </div>
    </div>
    <div className={`${classes.content} ${classes.articles}`}>
      {posts.map(a => (
        <PostCard {...a} />
      ))}
    </div>
  </div>
);

export default injectSheet(styles)(News);
