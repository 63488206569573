import React, { useState, useRef } from 'react';
import injectSheet from 'react-jss';
import { useKey, useClickAway } from 'react-use';
import arrowDown from './media/arrowDown.svg';
import arrowUp from './media/arrowUp.svg';
import { Route, Link } from 'react-router-dom';
import NavLinkSubnav from '../NavLinkSubnav/NavLinkSubnav';
import styles from './NavLinkStyles';

const NavLink = ({
  classes,
  exact,
  title,
  path,
  variant,
  external,
  subnav: subnavList,
  onClick,
}) => {
  // STATE
  const [isFocused, setIsFocused] = useState(false);
  const [showSubnav, setShowSubnav] = useState(false);

  // VARIABLES
  const hasSubnav = subnavList !== undefined;

  // HELPER FUNCTIONS
  const closeSubnav = () => {
    setShowSubnav(false);
    setIsFocused(false);
    onClick();
  };

  // INTERACTIONS
  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsFocused(false);
    setShowSubnav(false);
  });

  useKey('Escape', () => {
    closeSubnav();
  });

  // GENERATORS
  const subnav = hasSubnav && (
    <NavLinkSubnav subnav={subnavList} showSubnav={showSubnav} closeSubnav={closeSubnav} />
  );
  const generatedLink = (match) => {
    const activeClass = match && variant !== 'button' ? classes.active : classes.notActive;
    // external websites
    if (external) {
      return (
        <a
          aria-label={`Go to ${title}`}
          title={`Go to ${title}`}
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            closeSubnav();
          }}
          className={`${activeClass} ${classes.linkText}`}
        >
          {title}
        </a>
      );
    }
    // links that toggle a subnav
    if (hasSubnav) {
      const arrow = isFocused ? (
        <img alt="submenu dropdown open" src={arrowUp} />
      ) : (
        <img alt="submenu dropdown closed" src={arrowDown} />
      );
      return (
        <span ref={ref}>
          <button
            title={`See ${title} sections`}
            aria-label={`See ${title} sections`}
            onClick={() => {
              setIsFocused(!isFocused);
              setShowSubnav(!showSubnav);
            }}
            className={` ${classes.linkText} ${classes.button} ${
              isFocused ? classes.highlightedButton : ''
            } ${activeClass}`}
          >
            <span>{title}</span> {arrow}
          </button>
          {subnav}
        </span>
      );
    } else {
      // regular link on the website
      return (
        <Link
          aria-label={`Go to ${title}`}
          className={`${activeClass} ${classes.linkText}`}
          onClick={() => {
            closeSubnav();
          }}
          title={`Go to ${title}`}
          to={path}
        >
          {title}
        </Link>
      );
    }
  };

  const generateClasses = (match) => {
    const subNavClass = hasSubnav ? classes.subnav : '';
    return `${classes.root} ${classes[variant]} ${subNavClass}`;
  };

  return (
    <Route
      path={path}
      exact={exact}
      children={({ match }) => <div className={generateClasses(match)}>{generatedLink(match)}</div>}
    />
  );
};

export default injectSheet(styles)(NavLink);
