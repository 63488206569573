import React from 'react';
import { render } from 'react-snapshot';
import App from './App';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

render(
  <Router>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>,
  document.getElementById('root')
);
