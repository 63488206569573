import theme from '../../../theme';

const styles = {
  ...theme.presets,
  containerContent: {
    boxSizing: 'border-box',
    marginTop: '-120px', // // used for anchor link positioning
    paddingTop: '120px', // used for anchor link positioning
    width: '70%',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      width: '100%',
    },
  },
  containerHero: {
    maxWidth: 580,
    '& > h1': {
      fontSize: 55,
    },
    '& > h3': {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '30px',
    },
  },
  containerIcon: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 32px',
    width: '30%',
    '& > img': {
      width: '100%',
      maxWidth: 200,
    },
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      justifyContent: 'flex-start',
      width: '100%',
    },
  },
  containerListItem: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 16,
    justifyContent: 'center',
    padding: '70px 30px',
  },
  containerListItemEven: {
    background: theme.palette.backgroundGrey,
  },
  containerMaxWidth: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: theme.presets.maxContent.maxWidth,
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      flexDirection: 'column',
    },
  },
  hero: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '170px 30px 120px 30px',
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      padding: '170px 30px 70px 30px',
    },
  },
  list: {
    paddingInlineStart: '16px',
    '& > li': {
      paddingBottom: '12px',
    },
  },
  tangramLeft: {
    left: 0,
    position: 'absolute',
    top: 316,
    zIndex: -1,
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      display: 'none',
    },
  },
  tangramRight: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: -1,
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      display: 'none',
    },
  },
  title: {
    fontSize: 22,
  },
};

export default styles;
