import React from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import UtilsGeneratedLinkStyles from './UtilsGeneratedLinkStyles';

export const generateFormatedLinks = (links) => {
  return links.map((link) => {
    return { title: link.title, to: link.path };
  });
};

// link = {title: String; to: String;}
export const UtilsGeneratedLink = ({ classes, link }) => {
  const { title, to } = link;
  const isExternalLink = to.includes('https');
  const isHashlink = to.includes('#');
  let generatedLink;
  if (isExternalLink) {
    // use regular link
    generatedLink = (
      <a
        aria-label={`Go to ${title}`}
        className={classes.link}
        href={to}
        rel="noopener noreferrer"
        target="_blank"
        title={`Go to ${title}`}
      >
        {title}
      </a>
    );
  } else {
    // use React Router
    generatedLink = isHashlink ? (
      <HashLink
        aria-label={`Go to ${title}`}
        className={classes.link}
        smooth
        title={`Go to ${title}`}
        to={to}
      >
        {title}
      </HashLink>
    ) : (
      <Link
        aria-label={`Go to ${title}`}
        className={classes.link}
        rel="noopener noreferrer"
        title={`Go to ${title}`}
        to={to}
      >
        {title}
      </Link>
    );
  }
  return generatedLink;
};

export default injectSheet(UtilsGeneratedLinkStyles)(UtilsGeneratedLink);
