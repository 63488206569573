import theme from '../../../theme';

const styles = {
  ...theme.presets,
  content: {
    ...theme.presets.content,
    padding: '50px 30px 30px 30px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      display: 'block',
    },
  },
  headline: {
    marginBottom: 0,
    marginTop: 0,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 32,
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      maxWidth: '100%',
    },
  },
  newsletter: {
    backgroundColor: theme.palette.surfaces.surface2,
  },
  plain: {
    color: theme.palette.text.onSurface2,
    display: 'inline-block',
    fontSize: 16,
    marginTop: 8,
    marginBottom: 12,
    textDecoration: 'none',
    '& > a': {
      color: theme.palette.text.onSurface2,
    },
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    '& > a > button': {
      marginTop: 0,
      [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
        marginTop: 16,
      },
    },
  },
  social: {
    display: 'inline-block',
    marginTop: 8,
    marginBottom: 8,
    paddingRight: 16,
    width: 'fit-content',
  },
};

export default styles;
