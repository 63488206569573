import theme from '../../../../theme';

const styles = {
  ...theme.presets,
  containerContent: {
    width: '40%',
    padding: '0 64px',
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      width: '100%',
      padding: '0',
    },
  },
  containerImage: {
    width: '60%',
    '& > img': {
      maxWidth: '800px',
      width: '100%',
    },
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      width: '100%',
    },
  },
  containerInner: {
    maxWidth: `${theme.presets.maxContent.maxContentWidthLarge}px`,
    padding: '64px 32px',
  },
  containerOuter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  containerValue: {
    display: 'flex',
    maxWidth: `${theme.presets.maxContent.maxContentWidthLarge}px`,
    padding: '64px 0px',
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      flexDirection: 'column',
      padding: '32px 0',
    },
  },
  containerValueWrapReverse: {
    flexDirection: 'row-reverse',
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      flexDirection: 'column',
      padding: '32px 0',
    },
  },
  grayBackground: {
    background: theme.palette.backgroundGrey,
  },
  list: {
    paddingInlineStart: '16px',
    '& > li': {
      paddingBottom: '10px',
    },
  },
  sectionTitle: {
    fontSize: 38,
    textAlign: 'center',
    [`@media all and (max-width: ${theme.breakpoints.large}px)`]: {
      textAlign: 'left',
    },
  },
};

export default styles;
