import React, { useState } from 'react';
import injectSheet from 'react-jss';
import NavLink from '../NavLink/NavLink';
import routes from '../../../routes';
import icon from './media/menuIcon.svg';
import styles from './NavLinkListMobileStyles';
import iconClose from './media/iconClose.svg';

const NavLinkListMobile = ({ classes }) => {
  // STATE
  const [open, setMenuOpen] = useState(false);

  return (
    <div className={classes.root}>
      <button
        onClick={() => {
          setMenuOpen(true);
        }}
        className={classes.iconHamburger}
      >
        <img alt="mobile menu icon" src={icon} className={classes.icon} />
      </button>

      <div className={`${open ? classes.menuOpen : classes.menuClosed} ${classes.menu}`}>
        <button
          aria-label={'close mobile nav'}
          className={classes.close}
          onClick={() => {
            setMenuOpen(false);
          }}
          title={'close mobile nav'}
        >
          <img src={iconClose} alt="close" />
        </button>
        {routes
          .filter((r) => r.header)
          .map((route, i) => (
            <NavLink
              key={i}
              onClick={() => {
                setMenuOpen(false);
              }}
              variant="button"
              {...route}
            />
          ))}
      </div>
    </div>
  );
};

export default injectSheet(styles)(NavLinkListMobile);
