import theme from '../../../../theme';

const styles = {
  bigText: {
    ...theme.presets.bigText,
    [`@media all and (max-width: ${theme.breakpoints.small}px)`]: {
      paddingLeft: 24,
    },
  },
  person: {
    maxWidth: 400,
    width: 370,
    position: 'relative',
    marginBottom: 24,
  },
  title: {
    paddingRight: 24,
    [`@media all and (max-width: ${theme.breakpoints.small}px)`]: {
      paddingLeft: 24,
    },
  },
  bio: {
    paddingRight: 24,
    [`@media all and (max-width: ${theme.breakpoints.small}px)`]: {
      paddingLeft: 24,
    },
  },
  image: {
    width: '100%',
    maxWidth: 300,
    padding: 24,
  },
  plain: {
    margin: 2,
    color: 'black',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
  },
};

export default styles;
