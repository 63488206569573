import React from 'react';
import injectSheet from 'react-jss';
import theme from '../../theme';

const styles = {
  ...theme.presets,
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  text: {
    marginBlockEnd: 0,
    maxWidth: 700
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  quotationMark: {
    fontWeight: 'bold',
    fontSize: '110px',
    marginBottom: -80
  },
  photo: {
    marginRight: 50,
    width: 155,
    minWidth: 155,
    height: 155,
    borderRadius: 77.5
  },
  logo: {
    height: 80,
    marginRight: 10
  }
};

const Quote = ({ classes, photo, text, nameTitle, companyName, companyLogo }) => (
  <div className={classes.innerContent}>
    <div className={classes.root}>
      <img alt={nameTitle} src={photo} className={classes.photo} />
      <div>
        <div className={classes.quotationMark}>“</div>
        <h2 className={classes.text}>{text}</h2>
        <div className={classes.row}>
          {/* <img className={classes.logo} alt={companyName} src={companyLogo} /> */}
          <p>
            {nameTitle}
            <br />
            {companyName}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default injectSheet(styles)(Quote);
