import theme from '../../../theme';

const styles = {
  ...theme.presets,
  containerContent: {
    marginTop: '-160px', // used for anchor link positioning
    paddingTop: '160px', // used for anchor link positioning
  },
  tangramLeft: {
    ...theme.presets.tangram,
    position: 'absolute',
    left: -250,
    bottom: -20,
    transform: 'rotate(180deg)',
  },
  tangramRight: {
    ...theme.presets.tangram,
    position: 'absolute',
    right: -140,
    top: 70,
  },
  headlineSection: {
    maxWidth: 582,
    margin: '0 10%',
    marginBottom: 100,
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      marginBottom: 150,
    },
  },
  hiringContent: {
    position: 'relative',
    maxWidth: 960,
    padding: '180px 30px 0px 30px',
    margin: 'auto',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      padding: '100px 30px 0 30px',
    },
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  teamTitle: {
    textAlign: 'center',
  },
  joinUs: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  image: {
    width: '100%',
  },
  map: {
    objectFit: 'contain',
  },
};

export default styles;
