import React from 'react';
import injectSheet from 'react-jss';
import styles from './AboutBioStyles';

const AboutBio = ({ classes, name, title, photo, linked }) => (
  <div className={classes.person}>
    <a href={linked} target="_blank" rel="noopener noreferrer" className={classes.plain}>
      <img className={classes.image} alt={name} src={photo} />
    </a>
    <h3 className={classes.plain}> {name} </h3>
    <p className={classes.plain}> {title}</p>
  </div>
);

export default injectSheet(styles)(AboutBio);
