const breakpoints = {
  small: 500,
  medium: 800,
  large: 1200,
};

const palette = {
  primary: '#000000',
  secondary: '#ffffff',
  backgroundGrey: '#E7EAEF',
  border: '#000000',
  surfaces: {
    surface1: '#000',
    surface2: '#E7EAEF',
    surface3: '#E7EAEF',
    surface4: '#FFFFFF',
    surface5: '#D0D5D7',
  },
  text: {
    onSurface1: '#fff',
    onSurface2: '#000',
    onSurface3: '#000000',
    onSurface4: '#000000',
    onSurface5: '#000000',
  },
};

const fontFamily =
  "'Larsseit', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans','Droid Sans', 'Helvetica Neue', sans-serif";

const typography = {
  fontFamily: fontFamily,
  inputs: {
    fontSize: '16px',
  },
};

const border = {
  generic: `2px solid ${palette.border}`,
};

const button = {
  backgroundColor: palette.primary,
  color: palette.secondary,
  borderRadius: 35,
  border: 'none',
  fontSize: 17,
  height: 70,
  margin: '32px 0',
  padding: '12px 42px',
  cursor: 'pointer',
};

const presets = {
  button,
  secondaryButton: {
    ...button,
    height: 64,
    borderRadius: 32,
  },
  maxContent: {
    maxWidth: 1120,
    maxContentWidth: 960,
    maxContentWidthLarge: 1420,
    margin: 'auto',
  },
  content: {
    position: 'relative',
    maxWidth: 960,
    padding: '100px 30px 0px 30px',
    margin: 'auto',
    [`@media all and (max-width: ${breakpoints.medium}px)`]: {
      padding: '100px 30px 0 30px',
    },
  },
  innerContent: {
    position: 'relative',
    maxWidth: 960,
    padding: '60px 30px 30px 30px',
    margin: 'auto',
    [`@media all and (max-width: ${breakpoints.medium}px)`]: {
      padding: '30x 30px 0 30px',
    },
  },
  tangram: {
    [`@media all and (max-width: ${breakpoints.medium}px)`]: {
      display: 'none',
    },
    zIndex: -1,
    position: 'absolute',
    width: '40%',
    maxWidth: 480,
  },
  textColumn: {
    maxWidth: 388,
  },
  textSection: {
    padding: '4px 0 12px 0',
    cursor: 'pointer',
  },
  bigText: {
    fontSize: 26,
    lineHeight: '36px',
  },
  smallerText: {
    fontSize: 16,
    lineHeight: '25px',
  },
  link: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: palette.primary,
  },
};

const theme = {
  border,
  breakpoints,
  palette,
  presets,
  typography,
};

export default theme;
