import React from 'react';
import injectSheet from 'react-jss';
import theme from '../../../theme';
import leftTangram from './green-1.svg';
import rightTangram from './concrete.svg';
import stockPhoto from './people-with-dashboards.png';
import focus from './focus.svg';
import eliminate from './eliminate.svg';
import learn from './learn.svg';
import identify from './identify.svg';
import present from './present.svg';

const styles = {
  ...theme.presets,
  tangramLeft: {
    ...theme.presets.tangram,
    left: -650,
    bottom: -100
  },
  tangramRight: {
    ...theme.presets.tangram,
    right: -150,
    top: -120
  },
  headlineSection: {
    maxWidth: 582,
    margin: '0 10%',
    marginBottom: 50
  },
  productSection: {
    backgroundColor: theme.palette.backgroundGrey,
    marginTop: -110,
    paddingTop: 110
  },
  headlineText: {
    maxWidth: 380
  },
  headlineContent: {
    flexWrap: 'wrap-reverse',
    display: 'flex',
    flexDirection: 'row',
    ...theme.presets.content
  },
  valuePropSection: {
    padding: '0 0 100px 0',
    ...theme.presets.content
  },
  row: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  valueProp: {
    maxWidth: 380,
    marginTop: 24
  },
  valuePropIcon: {
    height: 124
  },
  videoContainer: {
    position: 'relative',
    overflow: 'show',
    flexGrow: 1
  },
  video: {
    maxWidth: 500,
    transform:
      'matrix3d(0.98,0,0.17,0.0003,0.00,0.98,0.17,0.0001,-0.17,-0.17,0.9603999999999999,0,0,0,0,1)',
    '-webkit-transform':
      'matrix3d(0.98,0,0.17,0.0003,0.00,0.98,0.17,0.0001,-0.17,-0.17,0.9603999999999999,0,0,0,0,1)'
  },
  image: {
    width: '100%'
  }
};

const ProjectTeam = ({ classes }) => (
  <div>
    <div className={classes.content}>
      <img alt="" src={leftTangram} className={classes.tangramLeft} />
      <img alt="" src={rightTangram} className={classes.tangramRight} />
      <div className={classes.headlineSection}>
        <h1>Project team</h1>
        <p>
          Project teams use Join to increase value for owners, discover and analyze new options
          during design and preconstruction, and present and make decisions with confidence. With
          Join, you can learn from past projects and save time and money before construction begins.
        </p>
        <a title="Get a demo" rel="noopener noreferrer" target="_blank" href="https://get.join.build/demo">
          <button className={classes.button}>Schedule A Demo</button>
        </a>
      </div>
    </div>
    <div className={classes.valuePropSection}>
      <h2>The Join Impact</h2>
      <div className={classes.row}>
        <div className={classes.valueProp}>
          <img alt="" src={focus} />
          <h3>Focus on creating value, not cutting costs</h3>
          <p className={classes.smallerText}>
            Changes and decisions made during preconstruction often focus too much on cost cutting
            and schedule impact. Join highlights and locks in unique project priorities to actually
            create value for owners.
          </p>
        </div>
        <div className={classes.valueProp}>
          <img alt="" src={identify} />
          <h3>More transparency leads to more ideas</h3>
          <p className={classes.smallerText}>
            Join considers each building’s unique requirements and then searches for and suggests
            manufactured products and materials that can improve the project. You can suggest these
            ideas to project partners, assign them for further evaluation, and connect to supplier
            and product specialists directly through Join to further the dialog.
          </p>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.valueProp}>
          <img alt="" src={present} />
          <h3>Present with power</h3>
          <p className={classes.smallerText}>
            Builders and designers engaged in value optimization are under pressure to deliver
            timely and thoroughly vetted options. Join creates presentation materials, detailed
            logs, and historical reports for decision makers so you can focus on more important
            tasks. When accepting or rejecting changes with the rest of the team, Join automatically
            calculates project impacts to prevent errors in on-the-fly calculations.
          </p>
        </div>
        <div className={classes.valueProp}>
          <img alt="" src={learn} />
          <h3>Learn from past projects</h3>
          <p className={classes.smallerText}>
            Join standardizes data for project information and value optimization so that you can
            leverage past experience to guide current projects. More importantly, you can present
            that information to quantify how you add value to your customers’ projects.
          </p>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.valueProp}>
          <img alt="" src={eliminate} />
          <h3>Eliminate tedious tasks</h3>
          <p className={classes.smallerText}>
            Stop managing multiple communication forums, digging through conflicting information,
            and creating detailed presentation materials. Automation helps erase hours of
            operational overhead and reduces human error while making quick decisions and updates
            during tight timelines.
          </p>
        </div>
        <div className={classes.valueProp}>
          <h3 className={classes.bigText}>
            Automation helps erase hours of operational overhead and reduces human error while
            making quick decisions and updates during tight timelines.
          </h3>
        </div>
      </div>
     <a title="Get a demo" rel="noopener noreferrer" target="_blank" href="https://get.join.build/demo">
        <button className={classes.button}>Schedule a Demo</button>
      </a>
    </div>
    <img className={classes.image} src={stockPhoto} alt={'People using Join'} />
  </div>
);

export default injectSheet(styles)(ProjectTeam);
