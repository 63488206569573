export const teamList = [
  {
    name: 'Dan Anthony',
    title: 'Senior Computational Designer',
    photo: '/teamPhotos/dan.png',
    link: 'https://www.linkedin.com/in/dantho/'
  },
  {
    name: 'Gustav Alexander Choto',
    title: 'Head of Customer Success',
    photo: '/teamPhotos/gustav.png',
    link: 'https://www.linkedin.com/in/gustavalexander/'
  },
  {
    name: 'Dennis Eldridge',
    title: 'Software Engineer',
    photo: '/teamPhotos/dennis.png',
    link: 'https://www.linkedin.com/in/dennis-eldridge-1262a2122/'
  },
  {
    name: 'Jim Forester',
    title: 'Co-Founder, Head of Business Development',
    photo: '/teamPhotos/jim.png',
    link: 'https://www.linkedin.com/in/jim-forester/'
  },
  {
    name: 'Dave Grundfest',
    title: 'Senior Software Engineer',
    photo: '/teamPhotos/dave.png',
    link: 'https://www.linkedin.com/in/dgrundfest'
  },
  {
    name: 'Josh Hogan',
    title: 'Head of Sales',
    photo: '/teamPhotos/josh.png',
    link: 'https://www.linkedin.com/in/josh-hogan-7a662031/'
  },
  {
    name: 'Kaitlyn Hova',
    title: 'Senior Software Engineer',
    photo: '/teamPhotos/hova.png',
    link: 'https://www.linkedin.com/in/kaitlynhova/'
  },
  {
    name: 'Andy Huh',
    title: 'Head of Building Product Solutions',
    photo: '/teamPhotos/andy.png',
    link: 'https://www.linkedin.com/in/andyhuh/'
  },
  {
    name: 'Jake Kempfer',
    title: 'Sales',
    photo: '/teamPhotos/jake.png',
    link: 'https://www.linkedin.com/in/jdkempfer/'
  },
  {
    name: 'Kristina U. Kosina',
    title: 'Software Engineer',
    photo: '/teamPhotos/kristina.png',
    link: 'https://www.linkedin.com/in/kkosina'
  },
  {
    name: 'Christina Landry',
    title: 'Head of Product',
    photo: '/teamPhotos/christina.png',
    link: 'https://www.linkedin.com/in/christinalandry/'
  },
  {
    name: 'Raenelle Love',
    title: 'Product Manager',
    photo: '/teamPhotos/raenelle.png',
    link: 'https://www.linkedin.com/in/raenelle/'
  },
  {
    name: 'Kevin Rakestraw',
    title: 'Software Engineer',
    photo: '/teamPhotos/kevin.png',
    link: 'https://www.linkedin.com/in/kevinrakestraw/'
  },
  {
    name: 'Jeff Sample',
    title: 'Head of Communications',
    photo: '/teamPhotos/jeff.png',
    link: 'https://www.linkedin.com/in/jeff-sample-b187087/'
  },
  {
    name: 'Erica Slavin',
    title: 'Software Engineer',
    photo: '/teamPhotos/erica.png',
    link: 'https://www.linkedin.com/in/ericaslavin/'
  },
  {
    name: 'Ezra Teshome',
    title: 'Sales',
    photo: '/teamPhotos/ezra.png',
    link: 'https://www.linkedin.com/in/ezra-teshome/'
  },
  {
    name: 'Drew Wolpert',
    title: 'Co-Founder, Head of Design',
    photo: '/teamPhotos/drew.png',
    link: 'https://www.linkedin.com/in/drewwolpert/'
  },
  {
    name: 'Andrew Zukoski',
    title: 'Co-Founder, CEO',
    photo: '/teamPhotos/andrew.png',
    link: 'https://www.linkedin.com/in/andrewzukoski/'
  }
];
