import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import monitor from './media/monitor.png';
import HomeValuePropsitionStyles from './HomeValuePropositionStyles';
import { generateValues } from './HomveValuePropositionData';
import video1 from './media/gif-1.gif';

const HomeValuePropsition = ({ classes }) => {
  // STATE
  const [video, setVideo] = useState(video1);

  // GENERATORS
  const values = generateValues(setVideo);
  const generatedValuesPrintout = values.map((value) => {
    const { button, onClick, title, description } = value;
    return (
      <div key={title}>
        <div className={classes.textSection} onClick={onClick}>
          <h3>{title}</h3>
          <div>{description}</div>
        </div>
        {button && (
          <div className={classes.textSection}>
            <Link title={button.title} to={button.url}>
              <button className={classes.secondaryButton}>{button.title}</button>
            </Link>
          </div>
        )}
      </div>
    );
  });
  return (
    <div>
      <div className={`${classes.innerContent} ${classes.row}`}>
        <h2>Get the design within budget on every project</h2>
        <div>
          The preconstruction phase of a project is the best time to evaluate options that will add
          the most value to your project. Too often, this turns into a complicated and contentious
          process making it difficult to understand progress towards your goals. Join is here to
          help.
        </div>
        <div className={classes.containerValues}>
          {generatedValuesPrintout}
          <div className={classes.containerVideo}>
            <img src={monitor} className={classes.imageMonitor} alt="" />
            <img className={classes.imageVideo} src={video} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(HomeValuePropsitionStyles)(HomeValuePropsition);
