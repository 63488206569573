import theme from '../../theme';

const styles = {
  ...theme.presets,
  containerInner: {
    display: 'flex',
    maxWidth: theme.presets.maxContent.maxWidth,
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      flexDirection: 'column',
    },
  },
  containerOuter: {
    backgroundColor: theme.palette.backgroundGrey,
    display: 'flex',
    justifyContent: 'center',
    padding: '150px 0 100px 0',
  },
  containerTestimonial: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
    padding: '15px',
    [`@media all and (max-width: ${theme.breakpoints.medium}px)`]: {
      paddingBottom: '100px',
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
  },
  testimonialBio: {
    backgroundColor: theme.palette.secondary,
    border: theme.border.generic,
    borderTop: 0,
    fontSize: '16px',
    lineHeight: '22px',
    minHeight: '66px',
    padding: '25px',
    textAlign: 'center',
  },
  testimonialBody: {
    backgroundColor: theme.palette.secondary,
    border: theme.border.generic,
    fontSize: '22px',
    fontWeight: 'bold',
    minHeight: '250px',
    padding: '25px',
    '& > img': {
      border: theme.border.generic,
      display: 'block',
      marginBottom: '-50px',
      maxWidth: '105px',
      margin: '0 auto',
      position: 'relative',
      top: '-84px',
    },
  },
};

export default styles;
