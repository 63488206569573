import React, { useState } from 'react';
import injectSheet from 'react-jss';
import Logo from '../../logo/Logo';
import NavLinkList from '../NavLinkList/NavLinkList';
import NavLinkListMobile from '../NavLinkListMobile/NavLinkListMobile';
import styles from './NavLinkBarStyles';

const NavLinkBar = ({ classes }) => {
  // STATE
  const [isScrolled, setScrolled] = useState(false);

  // INTERACTIONS
  window.onscroll = () => {
    if (window.pageYOffset >= 1) {
      setScrolled(true);
    } else if (window.pageYOffset < 1) {
      setScrolled(false);
    }
  };
  return (
    <div className={`${classes.sticky} ${isScrolled ? classes.stuck : ''}`}>
      <div className={classes.maxContent}>
        <div className={classes.root}>
          {isScrolled ? <Logo variant="wordmark" /> : <Logo variant="logo" />}
          <div className={classes.hiddenSmall}>
            <NavLinkList />
          </div>
          <div className={classes.shownSmall}>
            <NavLinkListMobile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(styles)(NavLinkBar);
